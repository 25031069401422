<template>
  <div>
    <div class="mass-action-settings">

      <cards-stage-select
        v-model="actionData.stage_type"
        placeholder="Выберите этап"
        size="small"
      ></cards-stage-select>

      <cards-stage-negotiation-select
        v-model="actionData.negotiation_type"
        placeholder="Выберите шаг"
        size="small"
        style="margin-left: 20px"
      ></cards-stage-negotiation-select>

      <el-select
        v-model="actionData.order"
        placeholder="Номер по порядку"
        size="small"
        style="margin-left: 20px"
      >
        <el-option
          v-for="order in 5"
          :key="order"
          :label="order"
          :value="order"
        >
        </el-option>
      </el-select>



        <el-button
          style="margin-left: 20px"
          size="small"
          @click="$refs.negotiationEditDialog.open(actionData.negotiation)"
        >
          Задать шаг
        </el-button>


      <div style="margin-top: 10px">
        Выбрано: {{ $companyConfiguration.cardStageNegotiations.getNegotiationNameByType(actionData.negotiation.type) }}
      </div>




      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите изменить шаг?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          style="margin-top: 20px"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Изменить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>


    <negotiation-edit-dialog
      ref="negotiationEditDialog"
      @negotiation-saved="setNegotiation"
    ></negotiation-edit-dialog>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import NegotiationEditDialog from "@/components/cards/CardStagesBlock/NegotiationEditDialog.vue";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";

export default {
  name: "card-update-negotiation-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageSelect, NegotiationEditDialog, CardsStageNegotiationSelect, MassActionResult},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.negotiation
        || !this.actionData.stage_type
        || !this.actionData.negotiation_type;
    }
  },

  data() {
    return {
      actionData: {
        stage_type: null,
        negotiation_type: null,
        order: null,
        negotiation: {},
      }
    }
  },
  methods: {
    setNegotiation(negotiation){
      this.actionData.negotiation = negotiation;
    }
  },
}
</script>
